import './App.css';
import Image1 from './assets/background/background.jpg';
import Footer from './components/Footer';
import Header from './components/Header';
import Links from './components/Links';

function App() {
  return (
    <div className='App'>
      <div className='min-h-screen flex flex-col items-center justify-center text-center' style={{backgroundImage : `url(${Image1})`, backgroundSize: 'cover', backgroundPosition: 'center'}}>
        <Header />
        <Links />
        <Footer />
      </div>
    </div>
  );
}

export default App;
