import React from 'react';
import Image1 from '../assets/icon/ristavia2.png';

const Header = () => {
  return (
    <header className="flex flex-col items-center justify-center text-center py-8">
      <img src={Image1} alt='icon' className='h-20 w-20 items-center' />
      <h1 className="text-2xl font-bold text-gray-200 mt-4">Free Template Power Point</h1>
      <p className="text-gray-600">Free Consulting and Create Your Best Template</p>
    </header>
  );
};

export default Header;
