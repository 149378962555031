import { cibFirebase, cibGmail, cibTiktok, cibWhatsapp } from '@coreui/icons';
import CIcon from '@coreui/icons-react';
import React from 'react';

const Links = () => {
    const links = [
        { id: 1, name: 'Email', url: 'mailto:helloo00ww.me@gmail.com', icon: cibGmail },
        { id: 2, name: 'Template PPT Gratis', url: 'https://drive.google.com/drive/folders/19-DE8tFnbDeYvbnc0LHe5rv2ImsWGM28?usp=sharing', icon: cibFirebase },
        { id: 3, name: 'Whatsapp', url: 'https://wa.me/6281995008067', icon: cibWhatsapp },
        { id: 4, name: 'TikTok', url: 'https://www.tiktok.com/@hello_me181?_t=8qkt1aa9BVi&_r=1', icon: cibTiktok }
    ];

    return (
        <div className="py-2">
            {links.map(link => (
                <a
                    key={link.id}
                    href={link.url}
                    className="block w-80 mx-auto text-center text-gray-800 bg-orange-600 hover:bg-orange-600 px-2 py-2 rounded mt-2"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    {/* <CIcon icon={link.icon} className="mr-2 w-6 h-6" />
                    {link.name} */}
                    <span className="inline-block">
                        <CIcon icon={link.icon} className="mr-2 w-6 h-6 inline-block align-middle" />
                        <span className="align-middle">{link.name}</span>
                    </span>
                </a>
            ))}
        </div>
    );
};

export default Links;
