import React from 'react';

const Footer = () => {
    return (
        <footer className="text-center py-4  mt-6">
            <p className="text-gray-600 opacity-40">&copy; 2024 Created by Rista. All rights reserved.</p>
        </footer>
    );
};

export default Footer;
